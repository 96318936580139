/* @import url("https://fonts.googleapis.com/css?family=Young Serif"); */
/* @import url('https://fonts.googleapis.com/css?family=Proxima+Nova'); */
/* @import url('https://fonts.googleapis.com/css?family=Domaine+Display'); */

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


@import url('leaflet/dist/leaflet.css');

@import url('animate.css/animate.css');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Domaine Display", "Proxima Nova1", system-ui, sans-serif;
  }
}

html, body {
  height: 100%;
  font-size:16px;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif ; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  -tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.leaflet-container {
  width: 100wh; 
  height: 100vh;
}

.sideMenu {
  height: fit-content;
  height:100vh;
}

.userMenu {
  position: absolute;
  bottom: 0%;
  width: 5rem;
  text-align: center;
}

.fc .fc-cell-shaded, .fc .fc-day-disabled {
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.fc-view-harness {
  @apply overflow-hidden overflow-x-auto shadow;
}

@screen sm {
  .fc-view-harness {
    @apply rounded-lg;
  }
}

/* .fc .fc-toolbar-title {
  font-size: 1.25rem !important;
  margin: 0;
  font-weight: 500;
  padding: 20px 0 0px 20px;
}

.fc .fc-button {
  background-color: #006082 !important;
  border-color: #006082 !important;
}

.fc-day-today {
  background-color: #edf5f7 !important;
}

.fc-theme-standard td {
  border: 1px solid #e5e7eb !important;
} 

.fc-day-other {
  background: #FAFAFB;
}

.fc .fc-button .fc-icon {
  font-size: 0.875rem !important;
}

a.fc-col-header-cell-cushion {
  font-size: .85em !important;
  line-height: 2.2rem !important;
  font-weight: 600 !important;
}

.fc .fc-daygrid-day-top {
  flex-direction: inherit !important;
  padding: 5px !important;
  font-size: .75em !important;
  color: #6b7280 !important;
}

.fc .fc-button-primary:disabled {
  background-color: #eeeeee !important;
  color: black !important;
  border-color: #eeeeee !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  text-transform: capitalize !important;
} */

